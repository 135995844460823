import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, FormLabel, Image, Row } from 'react-bootstrap';
import ReactGA from "react-ga4";

ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: 'Partners',
});

const Partner = () => {
    const [partner, setPartner] = useState([]);

    useEffect(() => {
        getPartner();
    }, []);

    const getPartner = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/partner_all');
            setPartner(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const partnerLine1 = (partner, index) => {
        if (partner.line === 1) {
            return (
                (partner.link ?
                    <a href={partner.link} target='_blank' rel='noreferrer' key={index}><Image src={process.env.REACT_APP_SITE_IMAGE + partner.url} className='ps-3 pe-3' height='100px' /></a>
                    :
                    <Image src={process.env.REACT_APP_SITE_IMAGE + partner.url} key={index} className='ps-3 pe-3' height='100px' />
                )
            );
        }
    }

    const partnerLine2 = (partner, index) => {
        if (partner.line === 2) {
            return (
                (partner.link ?
                    <a href={partner.link} target='_blank' rel='noreferrer' key={index}><Image src={process.env.REACT_APP_SITE_IMAGE + partner.url} className='ps-3 pe-3' height='100px' /></a>
                    :
                    <Image src={process.env.REACT_APP_SITE_IMAGE + partner.url} key={index} className='ps-3 pe-3' height='100px' />
                )
            );
        }
    }

    return (
        <div className="bg-body-tertiary pb-5" id={document.location.pathname === '/partner' ? 'container-page' : 'partner-page'} data-bs-theme="dark">
            <Container className='text-center'>
                <FormLabel className='fs-3 header-title'>PARTNERS</FormLabel>
                <Row>
                    <Container className='pt-5 partners'>
                        {partner.map((partner, index) => (
                            partnerLine1(partner, index)
                        ))}
                    </Container>
                    <Container className='pt-5 partners'>
                        {partner.map((partner, index) => (
                            partnerLine2(partner, index)
                        ))}
                    </Container>
                </Row>
            </Container>
        </div>
    );
}

export default Partner;