import React from 'react';
import { Col, Container } from 'react-bootstrap';

const Footer = () => {
    return (
        <div data-bs-theme="dark" style={{ width: '100%', height: '50px', backgroundColor: '#003399' }}>
            <Container className='pt-2 text-center'>
                <Col>
                    <span style={{ fontSize: '12px' }}>&copy; Copyright </span>
                    <span className='fw-bold' style={{ fontSize: '12px', color: '#FFCC00' }}>PT. Mega Bintang Persada </span>
                    <span style={{ fontSize: '12px' }}>All Rights Reserved</span>
                </Col>
            </Container>
        </div>
    );
}
export default Footer;
