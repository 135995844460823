import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import NavBar from "./components/Navbar";
import Home from "./components/Home";
import GameScheduleAll from "./components/GameScheduleAll";
import NewsAll from "./components/NewsAll";
import NewsDetail from "./components/NewsDetail";
import About from "./components/About";
import Partner from "./components/Partner";
import Player from "./components/Player";
import Team from "./components/Team";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import PlayerDetail from "./components/PlayerDetail";
import GalleryDetail from "./components/GalleryDetail";

ReactGA.initialize("G-WDE4PC7C4N");

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<><NavBar /><Home /><Footer /></>}></Route>
        <Route path="/about" element={<><NavBar /><About /><Footer /></>}></Route>
        <Route path="/gallery" element={<><NavBar /><Gallery /><Footer /></>}></Route>
        <Route path="/gallery/:url_subject" element={<><NavBar /><GalleryDetail /><Footer /></>}></Route>
        <Route path="/game-schedules" element={<><NavBar /><GameScheduleAll /><Footer /></>}></Route>
        <Route path="/news" element={<><NavBar /><NewsAll /><Footer /></>}></Route>
        <Route path="/news/:url_subject" element={<><NavBar /><NewsDetail /><Footer /></>}></Route>
        <Route path="/roster" element={<><NavBar /><Player /><Footer /></>}></Route>
        <Route path="/roster/:url_subject" element={<><NavBar /><PlayerDetail /><Footer /></>}></Route>
        <Route path="/partner" element={<><NavBar /><Partner /><Footer /></>}></Route>
        <Route path="/coaching-staff" element={<><NavBar /><Team /><Footer /></>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
