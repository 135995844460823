import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row, Image } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

const NewsDetail = () => {
    const [news, setNews] = useState([]);
    const [newsTag, setNewsTag] = useState([]);
    const [tags, setTags] = useState([]);

    const { url_subject } = useParams();

    useEffect(() => {
        getNews(url_subject);
        if (window.instgrm)
            window.instgrm.Embeds.process();
    }, [url_subject]);

    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: news.subject,
    });

    const getNews = async (url_subject) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/news_detail/${url_subject}`);
            setNews(response.data.result[0]);
            setNewsTag([]);
            setTags([]);

            let tagId = response.data.result[0]['tag_id'];
            let newsId = response.data.result[0]['id'];

            if (tagId) {
                try {
                    const resTag = await axios.get(process.env.REACT_APP_SITE + `/news_tag_all?tags=${tagId}`);
                    setTags(resTag.data);

                    const resNewsTag = await axios.get(process.env.REACT_APP_SITE + `/news?tags=${tagId}&not=${newsId}&limit=3`);
                    setNewsTag(resNewsTag.data.result);
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const convertDateTime = (dateTime) => {
        // convert date
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(dateTime);

        let d = date.getDate();
        let m = month[date.getMonth()];
        let y = date.getFullYear();
        let t = date.toTimeString().slice(0, 5);
        let convertDateTime = m + ' ' + d + ' , ' + y + ' | ' + t;

        return convertDateTime;
    }

    const related = (news) => {
        let url = '/news/';

        if (JSON.parse(news.image_id).length > 0) {
            url = '/gallery/';
        }

        return (
            <>
                <a href={`${url + news.url_subject}`}><Card.Img variant="top" src={process.env.REACT_APP_SITE_IMAGE + news.path + news.filename} /></a>
                <Card.Body>
                    <Card.Title className='text-start'><a href={`${url + news.url_subject}`} className='link'>{news.subject}</a></Card.Title>
                    <Card.Text className='text-start'>{convertDateTime(news.date_publish)}</Card.Text>
                </Card.Body>
            </>
        )
    }

    return (
        <div className="bg-body-tertiary pb-5" id='container-page' data-bs-theme="dark">
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <h3>{news.subject}</h3>
                        <h6>{convertDateTime(news.date_publish)}</h6>
                        <Row className='justify-content-center mt-3'>
                            <Image src={process.env.REACT_APP_SITE_IMAGE + news.path + news.filename} rounded style={{ width: '100%' }} />
                            <span className='mt-2' id='caption'>{(news.caption || '') + ' | ' + news.image_copyright}</span>
                            <Col lg={11} className='mt-5'>
                                <Row className='newsDesc'>
                                    <p dangerouslySetInnerHTML={{ __html: news.description }} />
                                </Row>
                                <h4>Tags :</h4>
                                {tags ?
                                    tags.map((tag, index) => (
                                        <Button key={index} href={`/news?tag=${tag.name}`} bg="primary" size='sm' className="me-1 tags">{tag.name}</Button>
                                    ))
                                    : ''
                                }
                                <hr />
                                <h4>Related :</h4>
                                <Row xs={1} sm={2} md={3} className="g-4 pb-5 mt-3 news">
                                    {newsTag.map((news, index) => (
                                        <Col key={index}>
                                            <Card className='h-100'>
                                                {related(news)}
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default NewsDetail;