import React, { useEffect, useState } from 'react';
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare, faSquareXTwitter, faSquareYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import logo from '../assets/image/prawira_logo_nama.png';
import bgHeader from '../assets/image/bg_header_blue.png';

const NavBar = () => {
    const [showTeam, setShowTeam] = useState(false);
    const [navDropdownActive, setNavDropdownActive] = useState(false);

    useEffect(() => {
        const select = (el, all = false) => {
            el = el.trim()
            if (all) {
                return [...document.querySelectorAll(el)]
            } else {
                return document.querySelector(el)
            }
        }

        const onscroll = (el, listener) => {
            el.addEventListener('scroll', listener)
        }

        let selectLogo = select('#prawiraLogo')
        if (selectLogo) {
            const logoScrolled = () => {
                if (window.scrollY > 100) {
                    selectLogo.classList.add('logo-scrolled')
                } else {
                    selectLogo.classList.remove('logo-scrolled')
                }
            }
            window.addEventListener('load', logoScrolled)
            onscroll(document, logoScrolled)
        }

        let selectNavMenu = select('#navMenu')
        if (selectNavMenu) {
            const logoScrolled = () => {
                if (window.scrollY > 100) {
                    selectNavMenu.classList.add('navMenu-scrolled')
                } else {
                    selectNavMenu.classList.remove('navMenu-scrolled')
                }
            }
            window.addEventListener('load', logoScrolled)
            onscroll(document, logoScrolled)
        }

        // nav active club
        if (window.location.pathname.split('/')[1] === 'about') {
            localStorage.setItem('navActive', 5);
            setNavDropdownActive(true);
        } else if (window.location.pathname.split('/')[1] === 'coaching-staff') {
            localStorage.setItem('navActive', 6);
            setNavDropdownActive(true);
        } else if (window.location.pathname.split('/')[1] === 'roster') {
            localStorage.setItem('navActive', 7);
            setNavDropdownActive(true);
        } else if (window.location.pathname.split('/')[1] === 'partner') {
            localStorage.setItem('navActive', 8);
            setNavDropdownActive(true);
        }
    }, []);

    const handleSelect = (eventKey) => {
        localStorage.setItem('navActive', eventKey);
    };

    // nav active news or gallery
    if (window.location.pathname.split('/')[1] === 'news') {
        localStorage.setItem('navActive', 3);
    } else if (window.location.pathname.split('/')[1] === 'gallery') {
        localStorage.setItem('navActive', 4);
    } else if (window.location.pathname === '/') {
        localStorage.setItem('navActive', 1);
    }

    return (
        <>
            <Navbar className='socmed' data-bs-theme="dark">
                <Container>
                    <Navbar.Collapse className="justify-content-end">
                        <span className='me-2'>Follow Us On :</span>
                        <Navbar.Text>
                            <Link className='me-2' to={'https://www.youtube.com/PrawiraTV'} target='_blank' title='Youtube Prawira Harum Bandung'><FontAwesomeIcon icon={faSquareYoutube} /></Link>
                            <Link className='me-2' to={'https://www.instagram.com/prawirabandung/'} target='_blank' title='Instagram Prawira Harum Bandung'><FontAwesomeIcon icon={faInstagramSquare} /></Link>
                            <Link className='me-2' to={'https://www.tiktok.com/@prawirabandung'} target='_blank' title='Tiktok Prawira Harum Bandung'><FontAwesomeIcon icon={faTiktok} /></Link>
                            <Link className='me-2' to={'https://www.facebook.com/PrawiraBandung/'} target='_blank' title='Facebook Prawira Harum Bandung'><FontAwesomeIcon icon={faFacebookSquare} /></Link>
                            <Link className='me-2' to={'https://twitter.com/PrawiraBandung'} target='_blank' title='TwitterX Prawira Harum Bandung'><FontAwesomeIcon icon={faSquareXTwitter} /></Link>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Navbar className='navbarMenu' expand='lg' data-bs-theme="dark">
                <Image id='bgHeader' src={bgHeader} />
                <Container>
                    <Navbar.Brand>
                        <a href='/' onClick={() => handleSelect(1)}><img src={logo} id='prawiraLogo' alt='prawira logo' /></a>
                    </Navbar.Brand>
                    <Navbar.Toggle id='toggleMenu' aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className='float-end text-end' defaultActiveKey='1' activeKey={localStorage.getItem('navActive')} onSelect={handleSelect} id='navMenu'>
                            <Nav.Link className='fs-5' eventKey='1' href='/'>HOME</Nav.Link>
                            <Nav.Link className='fs-5' eventKey='2' href='/game-schedules'>GAME SCHEDULE</Nav.Link>
                            <Nav.Link className='fs-5' eventKey='3' href='/news'>NEWS</Nav.Link>
                            <Nav.Link className='fs-5' eventKey='4' href='/gallery'>GALLERY</Nav.Link>
                            <NavDropdown className='fs-5' title="CLUB" id="basic-nav-dropdown" show={showTeam} onClick={() => setShowTeam(!showTeam)} onMouseEnter={(e) => setShowTeam(true)} onMouseLeave={(e) => setShowTeam(false)} active={navDropdownActive}>
                                <NavDropdown.Item className='link fs-5' eventKey='5' href='/about'>ABOUT</NavDropdown.Item>
                                <NavDropdown.Item className='link fs-5' eventKey='6' href='/coaching-staff'>COACHING STAFF</NavDropdown.Item>
                                <NavDropdown.Item className='link fs-5' eventKey='7' href='/roster'>ROSTER</NavDropdown.Item>
                                <NavDropdown.Item className='link fs-5' eventKey='8' href='/partner'>PARTNERS</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link className='fs-5' eventKey='9' href='https://shopee.co.id/prawirabandung' target='_blank'>STORE</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
export default NavBar;
