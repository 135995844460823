import React, { useEffect, useState } from 'react';
import { Card, Col, Container, FormLabel, Row, Form } from 'react-bootstrap';
import axios from 'axios';
import ReactGA from "react-ga4";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons';

ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: 'Gallery',
});

const Gallery = () => {
    const [gallery, setGallery] = useState([]);

    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(0);
    const [keyword, setKeyword] = useState("");

    useEffect(() => {
        getGallery(page, keyword);
    }, [page, keyword]);

    const getGallery = async (page, keyword) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/gallery_all?search_query=${keyword}&page=${page}&limit=12`);
            setGallery(response.data.result);
            setPage(response.data.page);
            setRows(response.data.totalRows);
        } catch (error) {
            console.log(error);
        }
    }

    const convertDateTime = (dateTime) => {
        // convert date
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(dateTime);

        let d = date.getDate();
        let m = month[date.getMonth()];
        let y = date.getFullYear();
        let t = date.toTimeString().slice(0, 5);
        let convertDateTime = m + ' ' + d + ' , ' + y + ' | ' + t;

        return convertDateTime;
    }

    return (
        <div className="bg-body-tertiary pb-5" id='container-page' data-bs-theme="dark">
            <Container className='text-center'>
                <FormLabel className='fs-3 header-title'>GALLERY</FormLabel>
                <Col lg={12} id='searchNews'>
                    <Row className='justify-content-end'>
                        <Col lg={3}>
                            <Form.Control type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} placeholder="Search..." />
                        </Col>
                    </Row>
                </Col>
                {gallery.length > 0 ?
                    <Row xs={1} sm={2} md={3} lg={4} className="g-4 pb-5 mt-2 gallery">
                        {gallery.map((gallery, index) => (
                            <Col key={index}>
                                <Card className='h-100'>
                                    <a href={`/gallery/${gallery.url_subject}`}><Card.Img variant="top" src={process.env.REACT_APP_SITE_IMAGE + gallery.images[0]} /></a>
                                    <Card.Body>
                                        <Card.Title className='text-start'><a href={`/gallery/${gallery.url_subject}`} className='link'>{gallery.subject}</a></Card.Title>
                                        <Card.Text className='text-start'>{convertDateTime(gallery.date_publish)}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    :
                    <FormLabel className='fs-3 text-center mt-5'><FontAwesomeIcon icon={faFaceFrown} /> GALLERY NOT FOUND</FormLabel>
                }

                <PaginationControl page={page} between={4} total={rows} limit={12} changePage={(page) => { setPage(page) }} ellipsis={1} />
            </Container>
        </div>
    );
}

export default Gallery;