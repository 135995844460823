import React, { useEffect, useState } from 'react';
import { Card, Col, Container, FormLabel, Row } from 'react-bootstrap';
import axios from 'axios';
import ReactGA from "react-ga4";

ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: 'Team',
});

const Team = () => {
    const [team, setTeam] = useState([]);

    useEffect(() => {
        getTeam();
    }, []);

    const getTeam = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/team_all`);
            setTeam(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="bg-body-tertiary pb-5" id='container-page' data-bs-theme="dark">
            <Container className='text-center'>
                <FormLabel className='fs-3 header-title'>COACHING STAFF</FormLabel>
                <Row xs={2} sm={3} md={4} lg={5} xl={6} className="g-4 pb-5 mt-2 team">
                    {team.map((team, index) => (
                        <Col key={index}>
                            <Card className='h-100 border-0'>
                                <Card.Img variant="top" src={process.env.REACT_APP_SITE_IMAGE + team.path + team.filename} />
                                <div className='divTitleText pt-3 pb-3'>
                                    <Card.Title className='header-title ps-2 pe-2'>{team.name}</Card.Title>
                                    <Card.Text style={{ marginTop: 'auto' }}>{team.position}</Card.Text>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default Team;