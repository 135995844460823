import React, { useEffect, useState } from 'react';
import { Card, Col, Container, FormLabel, Row, Table } from 'react-bootstrap';
import axios from 'axios';
import ReactGA from "react-ga4";

ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: 'Players',
});

const Player = () => {
    const [player, setPlayer] = useState([]);

    useEffect(() => {
        getPlayer();
    }, []);

    const getPlayer = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/player_all`);
            setPlayer(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="bg-body-tertiary pb-5" id='container-page' data-bs-theme="dark">
            <Container className='text-center'>
                <FormLabel className='fs-3 header-title'>ROSTER</FormLabel>
                <Row xs={2} sm={3} md={4} lg={5} xl={6} className="g-4 pb-5 mt-2 player">
                    {player.map((player, index) => (
                        <Col key={index}>
                            <Card className='h-100 border-0'>
                                <a href={`/roster/${player.name}`}><Card.Img variant="top" src={process.env.REACT_APP_SITE_IMAGE + player.path + player.filename} /></a>
                                <div className='divTitleText pt-3'>
                                    <Card.Title className='header-title text-white ps-2 pe-2'><a href={`/roster/${player.name}`} className='link'>{player.name}</a></Card.Title>
                                    <Table style={{ marginTop: 'auto' }}>
                                        <tbody>
                                            <tr>
                                                <td className='align-middle border-bottom-0 border-end' style={{ width: '50%' }}><Card.Title className='text-warning header-title fs-1'>{player.shirt_number}</Card.Title></td>
                                                <td className='align-middle border-bottom-0' style={{ width: '50%' }}><Card.Title className='text-primary header-title fs-1' style={{ wordBreak: 'break-all' }}>{player.position}</Card.Title></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default Player;