import React, { useEffect, useState } from 'react';
import { Col, Container, FormLabel, Row, Image } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

const PlayerDetail = () => {
    const [player, setPlayer] = useState([]);

    const { url_subject } = useParams();

    useEffect(() => {
        getPlayer(url_subject);
    }, [url_subject]);

    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: player.name,
    });

    const getPlayer = async (url_subject) => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/player_detail/${url_subject}`);
            setPlayer(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const convertDate = (dob) => {
        // convert date
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(dob);

        let d = date.getDate();
        let m = month[date.getMonth()];
        let y = date.getFullYear();
        let convertDate = d + ' ' + m + ' ' + y;

        return convertDate;
    }

    const age = (date) => {
        var dob = new Date(date);
        var monthDiff = Date.now() - dob.getTime();
        var ageDt = new Date(monthDiff);
        var year = ageDt.getUTCFullYear();
        var age = Math.abs(year - 1970);

        return age;
    }

    return (
        <div className="bg-body-tertiary pb-5" id='container-page' data-bs-theme="dark">
            <Container className='text-center'>
                <FormLabel className='fs-3 header-title'>ROSTER OVERVIEW</FormLabel>
                <Row className='justify-content-center'>
                    <Col xs={10} sm={7} md={5} lg={4} xl={3} xxl={3}>
                        <Row className='mt-3'>
                            <Image src={process.env.REACT_APP_SITE_IMAGE + player.path + player.filename} rounded />
                        </Row>
                    </Col>
                    <Col xs={10} sm={10} md={7} lg={6} xl={5} xxl={5} className='playerDetail'>
                        <Row className='mt-4'>
                            <Col xs={12} className='text-start ps-4 pt-3 pb-4 bgDetail'>
                                <h1 className='header-title fs-3'>{player.name}</h1>
                                <span id='dob'>{player.place_of_birth}, {convertDate(player.date_of_birth)}</span>
                            </Col>

                            {/* web */}
                            <Col className='mt-4 pt-4 bgDetail web'>
                                <span>NUMBER</span>
                                <h1 className='header-title' id='number'>{player.shirt_number}</h1>
                            </Col>
                            <Col className='mt-4 d-grid web'>
                                <Col xs={12} className='pt-3 bgDetail web' id='detail'>
                                    <span>AGE</span>
                                    <h1 className='header-title'>{player.date_of_birth ? age(player.date_of_birth) : ''}</h1>
                                </Col>
                                <Col xs={12} className='mt-4 pt-3 bgDetail web' id='detail'>
                                    <span>WEIGHT</span>
                                    <h1 className='header-title'>{player.weight} kg</h1>
                                </Col>
                            </Col>
                            <Col className='mt-4 d-grid web'>
                                <Col xs={12} className='pt-3 bgDetail web' id='detail'>
                                    <span>POSITION</span>
                                    <h1 className='header-title'>{player.position}</h1>
                                </Col>
                                <Col xs={12} className='mt-4 pt-3 bgDetail web' id='detail'>
                                    <span>HEIGHT</span>
                                    <h1 className='header-title'>{player.height} cm</h1>
                                </Col>
                            </Col>

                            {/* mobile */}
                            <Col xs={12} className='pt-3 bgDetail mobile'>
                                <span>NUMBER</span>
                                <h1 className='header-title' id='number'>{player.shirt_number}</h1>
                            </Col>
                            <Col className='ps-0 mobile'>
                                <Col className='mt-4 pt-3 pb-2 bgDetail mobile'>
                                    <span>AGE</span>
                                    <h1 className='header-title'>{player.date_of_birth ? age(player.date_of_birth) : ''}</h1>
                                </Col>
                                <Col className='mt-4 pt-3 pb-2 bgDetail mobile'>
                                    <span>WEIGHT</span>
                                    <h1 className='header-title'>{player.weight} kg</h1>
                                </Col>
                            </Col>
                            <Col className='pe-0 mobile'>
                                <Col className='mt-4 pt-3 pb-2 bgDetail mobile'>
                                    <span>POSITION</span>
                                    <h1 className='header-title'>{player.position}</h1>
                                </Col>
                                <Col className='mt-4 pt-3 pb-2 bgDetail mobile'>
                                    <span>HEIGHT</span>
                                    <h1 className='header-title'>{player.height} cm</h1>
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PlayerDetail;