import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormLabel, Nav, Row } from 'react-bootstrap';
import axios from 'axios';

const gameScheduleTabelStyles = {
    fontSize: '12px',
    fontFamily: 'Aller Regular',
}

const GameSchedule = () => {
    const [gameSchedule, setGameSchedule] = useState([]);

    useEffect(() => {
        getGameSchedule();
    }, []);

    const getGameSchedule = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/schedule_join_all');
            setGameSchedule(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const gameScheduleTable = (game, index) => {
        // style border
        let styleBorder = {};

        // status game
        const gameStatus = ['', 'RESULT', 'NEXT', 'UPCOMING', 'POSTPONED'];
        let statusGame = <div style={{ marginTop: '-5px', lineHeight: '0' }}>{gameStatus[game.status]}</div>;
        let showMobile = 'd-none d-lg-block';

        if (game.status === 1) {
            showMobile = 'd-none d-xl-block';

            if (gameSchedule.length === 1) {
                showMobile = 'd-block';
            } else {
                styleBorder = { borderRight: '2px solid', height: '90px' }
            }
        }

        if (game.status === 2) {
            showMobile = 'd-block';

            if (showMobile === 'd-block d-sm-none') {
                statusGame = <span>{gameStatus[game.status]}</span>;
            }

            if (game.ticket && (game.stream || game.stats)) {
                if (game.stream) {
                    statusGame =
                        <>
                            <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                            <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                        </>;
                } else {
                    statusGame =
                        <>
                            <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                            <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                        </>;
                }
            } else if (game.ticket) {
                statusGame = <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -33px' }}>BUY TICKET</Button>;
            } else if (game.stream) {
                statusGame = <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
            } else if (game.stats) {
                statusGame = <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
            }
        }

        if (game.status === 3) {
            if (game.ticket && (game.stream || game.stats)) {
                if (game.stream) {
                    statusGame =
                        <>
                            <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                            <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                        </>;
                } else {
                    statusGame =
                        <>
                            <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -49px' }}>BUY TICKET</Button>
                            <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 17px' }}>LIVE <span className='iconLive'>&#8226;</span></Button >
                        </>;
                }
            } else if (game.ticket) {
                statusGame = <Button size="sm" className='btnLink' href={game.ticket} target='_blank' style={{ margin: '-17px 0 0 -33px' }}>BUY TICKET</Button>;
            } else if (game.stream) {
                statusGame = <Button size="sm" className='btnLink' href={game.stream} target='_blank' style={{ margin: '-17px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
            } else if (game.stats) {
                statusGame = <Button size="sm" className='btnLink' href={game.stats} target='_blank' style={{ margin: '-17px 0 0 -15px' }}>LIVE <span className='iconLive'>&#8226;</span></Button>
            }
        }

        if (game.status === 3 || game.status === 4) {
            styleBorder = { borderLeft: '2px solid', height: '90px' }
        }

        // convert date
        const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        let date = new Date(game.match_date);

        let day = days[date.getDay()];
        let d = date.getDate();
        let m = month[date.getMonth()];
        let y = date.getFullYear();
        let t = date.toTimeString().slice(0, 5);
        let convertDateTime = day + ' ' + m + ' ' + d + ' , ' + y + ' | ' + t + ' WIB';

        return (
            <Col key={index} style={styleBorder} className={showMobile}>
                <Nav activeKey={localStorage.getItem('navActive')} onSelect={handleSelect}>
                    <Nav.Link eventKey='2' href='/game-schedules' id='linkGameSchedule'>
                        <table style={gameScheduleTabelStyles}>
                            <tbody>
                                <tr>
                                    <td><img src={process.env.REACT_APP_SITE_IMAGE + game.team1_url} alt='team1' className='team' /></td>
                                    {game.status === 1 ? <td className='header-title fs-1 text-white' style={{ width: '70px' }}>{game.team1_score}</td> : ''}
                                    <td className='p-1 fs-5'>
                                        <img src={process.env.REACT_APP_SITE_IMAGE + game.competition_url} alt='competition' className='competition' />
                                        <h6 style={{ marginTop: '-20px' }}>VS</h6>
                                    </td>
                                    {game.status === 1 ? <td className='header-title fs-1 text-white' style={{ width: '70px' }}>{game.team2_score}</td> : ''}
                                    <td><img src={process.env.REACT_APP_SITE_IMAGE + game.team2_url} alt='team2' className='team' /></td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>
                                        {statusGame}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>{convertDateTime} | <span style={{ fontSize: '10px' }}>{game.venue}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </Nav.Link>
                </Nav>
            </Col>
        )
    }

    const handleSelect = (eventKey) => {
        localStorage.setItem('navActive', eventKey);
    };

    return (
        <>
            <div className="bg-body-tertiary" id='gameScheduleBg' data-bs-theme="dark"></div>
            <div id='gameSchedule'>
                <Container className='text-center'>
                    <FormLabel className='fs-5 header-title' id='title'>GAME SCHEDULE</FormLabel>
                    <Row>
                        {gameSchedule.map((game, index) => (
                            gameScheduleTable(game, index)
                        ))}
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default GameSchedule;