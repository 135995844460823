import React from 'react';
import ImageSlider from "./ImageSlider";
import GameSchedule from './GameSchedule';
import News from './News';
import Partner from './Partner';
import ReactGA from "react-ga4";
import { Col, Container, FormLabel, Row } from 'react-bootstrap';
import jersey1 from '../assets/image/jersey1.png';
import jersey2 from '../assets/image/jersey2.png';
import jersey3 from '../assets/image/jersey3.png';

ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: 'Prawira Harum Bandung',
});

const Home = () => {
    return (
        <>
            <ImageSlider />
            <GameSchedule />
            <div className="bg-body-tertiary pt-4" data-bs-theme="dark">
                <Container className='text-center'>
                    <FormLabel className='fs-3 header-title'>PRAWIRA JERSEY 2024</FormLabel>
                    <Row xs={1} sm={2} md={3} className="g-3 justify-content-center">
                        <Col>
                            <img width='100%' height='auto' src={jersey1} alt="jersey1" />
                        </Col>
                        <Col>
                            <img width='100%' height='auto' src={jersey2} alt="jersey2" />
                        </Col>
                        <Col>
                            <img width='100%' height='auto' src={jersey3} alt="jersey3" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <News />
            <Partner />
        </>
    );
}

export default Home;