import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel, Image, Nav } from 'react-bootstrap';

const ImageSlider = () => {
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        getSlides();
    }, []);

    const getSlides = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + '/banner_all');
            setSlides(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Carousel className='banner' fade indicators={window.innerWidth <= 768 ? true : false}>
            {slides.map((image, index) => (
                <Carousel.Item key={index}>
                    <Nav.Link href='#'>
                        <Image src={process.env.REACT_APP_SITE_IMAGE + image.path + image.filename} />
                    </Nav.Link>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default ImageSlider;