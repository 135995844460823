import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Image, FormLabel } from 'react-bootstrap';
import axios from 'axios';
import ReactGA from "react-ga4";

ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: 'About',
});

const About = () => {
    const [about, setAbout] = useState([]);

    useEffect(() => {
        getAbout();
    }, []);

    const getAbout = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_SITE + `/about`);
            setAbout(response.data[0]);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="bg-body-tertiary pb-5" id='container-page' data-bs-theme="dark">
            <Container className='text-center'>
                <FormLabel className='fs-3 header-title'>ABOUT</FormLabel>
                <Row className='justify-content-center'>
                    <Col lg={10}>
                        <Row className='justify-content-center mt-3'>
                            <Image src={process.env.REACT_APP_SITE_IMAGE + about.path + about.filename} width='100%' rounded />
                            <Col lg={11} className='mt-4'>
                                <Row>
                                    <p dangerouslySetInnerHTML={{ __html: about.description }} />
                                </Row>
                            </Col>
                            {about.filename2 ? (
                                <Image src={process.env.REACT_APP_SITE_IMAGE + about.path2 + about.filename2} width='100%' rounded />
                            ) : ('')}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default About;